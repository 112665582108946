html {
  min-height: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  position: relative;
}

body {
  min-height: 100%;
  height: 100%;
  color: #fff;
  font-family: 'Avenir Book';
}

body a {
  color: #fff;
  text-decoration: none;
}
