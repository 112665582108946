@font-face {
  font-family: "Avenir Book";
  src: local("Avenir Book"), url(./assets/fonts/Avenir-Book.ttf) format("ttf");
}

@font-face {
  font-family: "Avenir Medium";
  src: local("Avenir Medium"),
    url(./assets/fonts/Avenir-Medium.ttf) format("ttf");
}

body {
  background: #000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.notFound {
  height: 100vh;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
}

.notFound div {
  text-align: center;
}
